<template>
  <tr
    class="relative"
    :class="{
      'bg-off-white-1 dark:bg-dark-bgPrimary': index % 2 === 0,
    }"
  >
    <td class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-1 relative" :class="[isError && 'pb-6']">
      <input
        v-model="preVideoInfo.url"
        v-if="preVideoInfo.isEdit"
        class="
          w-full
          p-2
          appearance-none
          bg-off-white-1
          dark:bg-dark-bgPrimary
          text-xs
          md:text-base
          text-black-1
          dark:text-dark-textPrimary
          border border-grey-mid
          focus:ring-magenta focus:border-magenta
          rounded
        "
        :class="[errorInput['url'] && 'border-magenta']"
      />
      <span v-else class="p-3 md:p-3.5 lg:py-4 lg:px-7">
        {{ preVideoInfo.url }}
      </span>
    </td>

    <td class="text-xs md:text-sm lg:text-base text-magenta font-medium">
      <DeleteIcon class="h-5 w-5 md:h-7 md:w-7 cursor-pointer" @click="onDelete(index)" />
    </td>
    <td class="text-xs md:text-sm lg:text-base text-magenta font-medium">
      <component
        :is="preVideoInfo.isEdit ? 'ApproveIcon' : 'EditIcon'"
        class="h-5 w-5 md:h-7 md:w-7 cursor-pointer inline mr-2"
        :class="[preVideoInfo.isEdit && 'text-success']"
        @click="onEdit(index, preVideoInfo)"
      />
      <AddIcon class="h-5 w-5 md:h-7 md:w-7 cursor-pointer inline" @click="onAdd" v-if="canAddNew" />
    </td>
    <span class="absolute -bottom-1 left-1 text-danger dark:text-danger text-base" v-if="isError"> Invalid youtube embed, vimeo, video.isilive.ca </span>
  </tr>
</template>

<script>
import AddIcon from "@Assets/images/AddIcon";
import EditIcon from "@Assets/images/EditIcon";
import ApproveIcon from "@Assets/images/ApproveIcon";
import DeleteIcon from "@Assets/images/DeleteIcon";
import { empty, isValidUrl } from "@/utils";
export default {
  components: {
    AddIcon,
    EditIcon,
    DeleteIcon,
    ApproveIcon,
  },
  props: {
    preVideo: {
      type: Object,
    },
    index: {
      type: Number,
    },
    canAddNew: {
      type: Boolean,
    },
  },
  data() {
    return {
      preVideoInfo: {},
      errorInput: {},
    };
  },
  computed: {
    isError() {
      return this.errorInput["url"];
    },
  },
  watch: {
    preVideo: {
      handler(value) {
        this.preVideoInfo = value;
      },
      immediate: true,
    },
  },
  methods: {
    onDelete(preVideoIndex) {
      this.$emit("onDelete", preVideoIndex);
    },
    onAdd() {
      this.errorInput = {
        url: empty(this.preVideoInfo.url) || !isValidUrl(this.preVideoInfo.url),
      };
      if (!this.isError) {
        this.errorInput = {};
        this.$emit("onCreate");
      }
    },
    onEdit(preVideoIndex, data) {
      this.errorInput = {
        url: empty(this.preVideoInfo.url) || !isValidUrl(this.preVideoInfo.url),
      };
      if (!this.isError) {
        this.errorInput = {};
        this.$emit("onEdit", [preVideoIndex, data]);
      }
    },
  },
};
</script>
