<template>
  <div class="rounded border border-grey-light dark:border-dark-bgPrimary">
    <table class="text-left w-full">
      <thead>
        <tr>
          <th class="text-xs md:text-sm text-grey-mid font-bold">
            <div class="flex items-center relative p-3 md:p-3.5 lg:py-4 lg:px-7 pl-0">
              <span>Title</span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
            <div class="flex items-center relative p-3 md:p-3.5 lg:py-4 lg:px-7 pl-0">
              <span>Url</span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold">
            <div class="flex items-center relative p-3 md:p-3.5 lg:py-4 lg:px-7">
              <span>PIN Code</span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold" style="width: 45px">
            <div class="flex items-center relative">
              <span>Delete</span>
            </div>
          </th>

          <th class="text-xs md:text-sm text-grey-mid font-bold ml-2" style="width: 100px">
            <div class="flex items-center relative">
              <span>Update / Add</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <conference-item
          v-for="(e, index) in conferencesListDataTable"
          :key="index"
          :index="index"
          :conference="e"
          :canAddNew="index === conferencesListDataTable.length - 1"
          @onDelete="onDelete"
          @onCreate="onCreate"
          @onEdit="onEdit"
        />
      </tbody>
    </table>
    <div v-if="conferencesListDataTable.length > 0" class="flex border-t border-grey-light p-2">
      <button
        class="
          w-16
          md:w-20
          h-8
          md:h-10
          text-xs
          md:text-base
          uppercase
          font-bold
          text-grey-mid
          dark:text-dark-textPrimary
          bg-white
          dark:bg-dark-bgSecondary
          border-2 border-grey-light
          dark:border-dark-bgPrimary
          rounded-full
          mr-2
          focus:outline-none
          hover:text-magenta hover:border-magenta
          dark:hover:text-magenta dark:hover:border-magenta
          transition-all
        "
        @click.prevent="paginate(1)"
      >
        First
      </button>

      <div v-for="page in totalPages" :key="`pagination-${page}`">
        <span
          v-if="isDisplayPageNumber(page)"
          :class="page === data.currentPage ? 'border-magenta text-magenta' : 'border-grey-light dark:border-dark-bgPrimary text-grey-mid dark:text-dark-textPrimary'"
          class="
            flex
            justify-center
            items-center
            w-8
            md:w-10
            h-8
            md:h-10
            rounded-full
            border-2
            shadow-sm
            cursor-pointer
            mr-2
            hover:text-magenta hover:border-magenta
            dark:hover:text-magenta dark:hover:border-magenta
            transition-all
          "
          @click="paginate(page)"
        >
          {{ page }}
        </span>
      </div>

      <button
        class="
          w-16
          md:w-20
          h-8
          md:h-10
          text-xs
          md:text-base
          uppercase
          font-bold
          text-grey-mid
          dark:text-dark-textPrimary
          bg-white
          dark:bg-dark-bgSecondary
          border-2 border-grey-light
          dark:border-dark-bgPrimary
          rounded-full
          mr-2
          focus:outline-none
          hover:text-magenta hover:border-magenta
          dark:hover:text-magenta dark:hover:border-magenta
          transition-all
        "
        @click.prevent="paginate(totalPages)"
      >
        Last
      </button>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from "vue";

import { dynamicPaginate } from "@Helpers/datatable";

import ConferenceItem from "./item";
export default {
  name: "EventsListTable",
  components: {
    ConferenceItem,
  },
  emits: ["onCreate", "onEdit", "onDelete"],
  props: {
    conferenceList: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const {
      conferenceList: { value: conferenceData },
    } = toRefs(props);
    const data = reactive({
      currentPage: 1,
      perPage: 10,
      isLoading: false,
    });
    const conferencesListDataTable = computed(() => dynamicPaginate(conferenceData, data.currentPage, data.perPage));
    const totalPages = computed(() => Math.ceil(conferenceData.length / data.perPage));

    function paginate(p) {
      if (p === data.currentPage) return;
      // Set currentPage
      data.currentPage = p;
    }

    function isDisplayPageNumber(p) {
      if (totalPages.value < 3) return true;
      if (p >= data.currentPage - 1 && p <= data.currentPage + 1) return true;
      return false;
    }

    function onCreate() {
      emit("onCreate");
      setTimeout(() => {
        if (conferenceData.length > data.perPage * data.currentPage) {
          paginate(data.currentPage + 1);
        }
      }, 100);
    }
    function onEdit([conferenceIndex, record]) {
      emit("onEdit", [data.perPage * (data.currentPage - 1) + conferenceIndex, record]);
    }

    return {
      data,
      conferencesListDataTable,
      totalPages,
      paginate,
      isDisplayPageNumber,
      onEdit,
      onCreate,
    };
  },
  beforeUnmount() {
    this.emitter.all.delete("submit-confirm-dialog-delete-conference");
  },
  mounted() {
    // Listen submit confirm dialog
    this.emitter.on("submit-confirm-dialog-delete-conference", () => {
      const { type, payload, reducePage } = this.$store.state.auth.confirmDialog.confirmAction;
      switch (type) {
        case "DELETE_VIDEO_CONFERENCE":
          if (reducePage && this.data.currentPage > 1) {
            this.paginate(this.data.currentPage - 1);
          }
          this.$emit("onDelete", payload);
          break;
        default:
          return;
      }
      this.$store.dispatch("resetConfirmDialog");
    });
  },
  methods: {
    onDelete(conferenceIndex) {
      // Show confirm dialog
      this.$store.dispatch("toggleConfirmDialog", {
        eventListen: "submit-confirm-dialog-delete-conference",
        isConfirmDialog: true,
        title: "Delete video conference",
        message: "Are you sure you want to delete selected video conference?",
        confirmButtonText: "Delete",
        confirmAction: {
          type: "DELETE_VIDEO_CONFERENCE",
          payload: this.data.perPage * (this.data.currentPage - 1) + conferenceIndex,
          reducePage: this.data.perPage === conferenceIndex + 1,
        },
      });
    },
  },
};
</script>
