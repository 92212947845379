<template>
  <div class="rounded border border-grey-light dark:border-dark-bgPrimary">
    <table class="text-left w-full">
      <thead>
        <tr>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
            <div class="flex items-center relative p-3 md:p-3.5 lg:py-4 lg:px-7 pl-0">
              <span>Url</span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold" style="width: 45px">
            <div class="flex items-center relative">
              <span>Delete</span>
            </div>
          </th>

          <th class="text-xs md:text-sm text-grey-mid font-bold ml-2" style="width: 100px">
            <div class="flex items-center relative">
              <span>Update / Add</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <preVideo-item
          v-for="(e, index) in preVideoData"
          :key="index"
          :index="index"
          :preVideo="e"
          :canAddNew="index === preVideoData.length - 1 && preVideoData.length < data.limited"
          @onDelete="onDelete"
          @onCreate="onCreate"
          @onEdit="onEdit"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

import PreVideoItem from "./item";
export default {
  name: "EventsListTable",
  components: {
    PreVideoItem,
  },
  emits: ["onCreate", "onEdit", "onDelete"],
  props: {
    preVideoList: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const {
      preVideoList: { value: preVideoData },
    } = toRefs(props);
    const data = reactive({
      limited: 5,
      isLoading: false,
    });

    function onCreate() {
      emit("onCreate");
    }
    function onEdit([preVideoIndex, record]) {
      emit("onEdit", [preVideoIndex, record]);
    }

    return {
      data,
      preVideoData,
      onEdit,
      onCreate,
    };
  },
  beforeUnmount() {
    this.emitter.all.delete("submit-confirm-dialog-delete-preVideo");
  },
  mounted() {
    // Listen submit confirm dialog
    this.emitter.on("submit-confirm-dialog-delete-preVideo", () => {
      const { type, payload, reducePage } = this.$store.state.auth.confirmDialog.confirmAction;
      switch (type) {
        case "DELETE_PRE_VIDEO":
          this.$emit("onDelete", payload);
          break;
        default:
          return;
      }
      this.$store.dispatch("resetConfirmDialog");
    });
  },
  methods: {
    onDelete(preVideoIndex) {
      // Show confirm dialog
      this.$store.dispatch("toggleConfirmDialog", {
        eventListen: "submit-confirm-dialog-delete-preVideo",
        isConfirmDialog: true,
        title: "Delete video URL",
        message: "Are you sure you want to delete selected video URL?",
        confirmButtonText: "Delete",
        confirmAction: {
          type: "DELETE_PRE_VIDEO",
          payload: preVideoIndex,
        },
      });
    },
  },
};
</script>
